(function () {
    // 보고서 관련 스크립트
    var $ul = $(".mobile-select-wrap ul");
    var $lis = $ul.find("li");
    $ul.on("click", function() {
        $(this).toggleClass("on");
    });

    $lis.on("click", function() {
        $lis.removeClass("on");
        $(this).addClass("on");

    });

    // 숫자 타입에서 쓸 수 있도록 format() 함수 추가
    Number.prototype.numberFormat = function(){
        if(this==0) return 0;

        var reg = /(^[+-]?\d+)(\d{3})/;
        var n = (this + '');

        while (reg.test(n)) n = n.replace(reg, '$1' + ',' + '$2');

        return n;
    };

    // 문자열 타입에서 쓸 수 있도록 format() 함수 추가
    String.prototype.numberFormat = function(){
        var num = parseFloat(this);
        if( isNaN(num) ) return "0";

        return num.numberFormat();
    };

    Date.prototype.yyyymmdd = function () {
        var yyyy = this.getFullYear().toString();
        var mm = (this.getMonth() + 1).toString(); // getMonth() is zero-based
        var dd = this.getDate().toString();
        return yyyy + "-" + (mm[1] ? mm : "0" + mm[0]) + "-" + (dd[1] ? dd : "0" + dd[0]); // padding
    };

    $.fn.extend({
        loadingIcon: function(status, opt) {
            var _status;
            _status = status || "show";

            if(_status == "show") {
                var _spin = "<i class='fa fa-spinner fa-spin' style='margin-left: 4px'></i>";
                _spin = opt || _spin;
                this.prop("disabled", true);
                this.append(_spin);
            } else if ( _status == "hide") {
                this.prop("disabled", false);
                this.find("i").remove();
            }
        }
    });

    $('.tel1').mask('000-0000-0000');
    $('.reg-num').mask('000-00-00000');

    $(".mobile-gnb-menu > li ").on("click", function(e) {
        var $this = $(this);
        e.preventDefault();

        $this.toggleClass("on");
        if($this.find("ul").length > 0) {
            var $ul = $this.find("ul");
            if($ul) {
                $ul.slideToggle();
            }
        } else {
            var a = $this.find("> a");
            goUrl(a.attr("href"));
        }
    });
    $(".mobile-gnb-menu > li li > a").on("click", function() {
        var $this = $(this);
        goUrl($this.attr("href"));
    });

    function goUrl(url) {
        window.location.href = url;
    }

    $('[name="deleteFiles[]"]').on('change', function (e) {
        e.preventDefault();
        var $li = $(this).parents('li');
        $li.toggleClass('disabled');

        if ($li.hasClass('disabled')) {
            $li.find('a').css('text-decoration', 'line-through');
        } else {
            $li.find('a').css('text-decoration', 'none');
        }
    });

    $('body').on('click', 'a[action=confirm]', function(e) {
        var message = $(this).attr('message').replace(/\\n/mg, "\n");
        if (!confirm(message)) {
            e.preventDefault();
        }
    });

    getCampaignStatus = function(status){
        var statusClass = [];

        if(status == "ENTRY"){
            statusClass['class'] = "out";
            statusClass['label'] = "인플루언서 발표";
        } else if(status == "REQUEST"){
            statusClass['class'] = "collect";
            statusClass['label'] = "인플루언서 모집";
        }else if(status == "WAIT"){
            statusClass['class'] = "ing";
            statusClass['label'] = "기획중";
        }else if(status == "POSTING"){
            statusClass['class'] = "up";
            statusClass['label'] = "콘텐츠 등록";
        }else if(status == "DRAFT"){
            statusClass['class'] = "draft";
            statusClass['label'] = "원고 등록중";
        }else if(status == "STOP"){
            statusClass['class'] = "stop";
            statusClass['label'] = "중지";
        }
        else if(status == "CLIENT_PICK"){
            statusClass['class'] = "pick";
            statusClass['label'] = "인플루언서 선정";
        }
        return statusClass;
    };
})();

$(function() {
    // moment locale 설정
    moment.locale('ko');

    // ajax cross를 위한 설정
    $.ajaxSetup({
        xhrFields: {
            withCredentials: true
        }
    });
});

$(function () {
    Dropzone.autoDiscover = false;
    Dropzone.options.dropzone = {
        paramName: "files",
        uploadMultiple: false,
        parallelUploads: 1,
        acceptedFiles: 'image/*,application/pdf',
        maxFiles: 10,
        maxFilesize: 2, // MB
        addRemoveLinks: true,
        dictRemoveFile: '삭제',
        dictCancelUpload: '삭제',
        accept: function(file, done) {
            done();
        },
        sending: function(file, xhr, formData) {
            formData.append("_token", $('[name=_token]').val());
        },
        init: function() {
            this.on("success", function(file, response) {
                file.serverFileName = JSON.parse(response)[0];
            });
            this.on("removedfile", function(file) {
                var param = {};
                param._token = $('[name=_token]').val();
                param.fileName = file.serverFileName;
                $.post(this.removeUrl, param, function() {
                });
            });
        }
    };
});

$(function () {
    // 툴팁
    $(document).on('mouseover', 'i[data-toggle=tooltip]:not([data-original-title])', function() {
        $(this).popover({trigger: "hover", html: true});
        $(this).popover('show');
    });
});


