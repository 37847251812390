$(function() {

    // init daterangepicker
    $('div.input-group-daterange').each(initDaterangePicker);

});

var initDaterangePicker = function() {
    var data = $(this).data();
    var defaults = {
        linkedCalendars: false,
        autoUpdateInput: true,
        alwaysShowCalendars: true,
        startDate: $(this).find('input.daterange-start').val() || moment().subtract(3, 'month').add(1, 'days'),
        endDate: $(this).find('input.daterange-end').val() || moment(),
        opens: "left",
        locale: {
            format: data.format || 'YYYY-MM-DD',
            separator: ' ~ ',
            customRangeLabel: '사용자 설정',
            applyLabel: '적용',
            cancelLabel: '취소'
        }
    };
    var options = $.extend({}, defaults, data);

    // predefine 버튼 설정
    if (data.hasOwnProperty('predefine')) {
        if (data.predefine == 'after') {
            options['ranges'] = {
                '오늘': [moment(), moment()],
                '7일': [moment(), moment().add(6, 'days')],
                '14일': [moment(), moment().add(13, 'days')],
                '1개월': [moment(), moment().add(1, 'month').subtract(1, 'days')],
                '3개월': [moment(), moment().add(3, 'month').subtract(1, 'days')],
                '6개월': [moment(), moment().add(6, 'month').subtract(1, 'days')],
                '1년': [moment(), moment().add(1, 'year').subtract(1, 'days')],
                '상시': [moment(), moment('2100-12-31 23:59:59')]
            };
        } else if (data.predefine == 'facebook') {
            options['ranges'] = {
                '7일': [moment(), moment().add(6, 'days').add(2, 'day')],
                '14일': [moment(), moment().add(13, 'days').add(2, 'day')],
                '1개월': [moment(), moment().add(1, 'month').add(2, 'day').subtract(1, 'days')],
                '3개월': [moment(), moment().add(3, 'month').add(2, 'day').subtract(1, 'days')],
                '6개월': [moment(), moment().add(6, 'month').add(2, 'day').subtract(1, 'days')],
                '1년': [moment(), moment().add(1, 'year').add(2, 'day').subtract(1, 'days')]
            };
        }else {
            options['ranges'] = {
                '오늘': [moment(), moment()],
                '어제': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '최근 7일': [moment().subtract(6, 'days'), moment()],
                '최근 14일': [moment().subtract(13, 'days'), moment()],
                '최근 1개월': [moment().subtract(1, 'month').add(1, 'days'), moment()],
                '최근 3개월': [moment().subtract(3, 'month').add(1, 'days'), moment()],
                '최근 6개월': [moment().subtract(6, 'month').add(1, 'days'), moment()],
                '최근 1년': [moment().subtract(1, 'year').add(1, 'days'), moment()]
            };
        }
    }

    // 날짜 변경 함수
    var applyDaterangepickerValue = function(start, end, label) {

        if (!this.ranges.hasOwnProperty(label) || (data.predefine == 'after') || (data.predefine == 'facebook')) {
            var label = start.format(this.locale.format) + ' ~ ' + end.format(this.locale.format);
        }

//                    label = start.format(this.locale.format) + ' ~ ' + end.format(this.locale.format);
//                    $.each(this.ranges, function(key, values) {
//                        if (values[0].diff(start, 'days') == 0 && values[1].diff(end, 'days') == 0) {
//                            label = key;
//                        }
//                    });

        $(this.element[0]).find('input.daterange-label').val(label);
        $(this.element[0]).find('input.daterange-start').val(start.format(this.locale.format));
        $(this.element[0]).find('input.daterange-end').val(end.format(this.locale.format));
        $(this.element[0]).trigger('change.daterangepicker', [this]);
    };

    // width 고정
    //$(this).width(250);

    // 표시되는 input을 readonly로 설정
    $(this).find('input.daterange-label').prop('readonly', true);

    // daterangepicker 초기화
    $(this).daterangepicker(options, applyDaterangepickerValue);

    // 초기값 표시하기
    $(this).data('daterangepicker').callback(
        $(this).data('daterangepicker').startDate,
        $(this).data('daterangepicker').endDate,
        'Custom Range'
    );
}
