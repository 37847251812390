// 차트 Default 옵션
Highcharts.setOptions({
    global: {
        useUTC: false
    },
    credits: {
        enabled: false,
        text: ''
    },
    lang: {
        noData: '데이터가 없습니다.',
        thousandsSep: ','
    }
});

var myOption = {
    fontFamily: 'roboto, notokr, sans-serif'
};

// COLOR SET
var PC_COLOR = '#a5a4c2';
var MOBILE_COLOR = '#6218f0';

window.chartOptions = {
    column: {
        chart: {
            type: 'column',
            style: {
                fontFamily: myOption.fontFamily
            }
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%Y.%m.%d',
                week: '%Y.%m.%d',
                month: '%Y.%m',
                year: '%Y'
            },
            labels: {
                //rotation: -25
            },
            reversedStacks: false
        },
        yAxis: {
            min: 0,
            title: {
                text: 'PV'
            },
            reversedStacks: false
        },
        tooltip: {
            headerFormat: '<b>{point.x:%Y-%m-%d}</b><br>',
            pointFormat: '<span style="color:{series.color};">{series.name}: {point.y}</span><br/>',
            //footerFormat: '합계: {point.stackTotal}',
            shared: true,
            crosshairs: true
        },
        plotOptions: {
            column: {
                stacking: 'normal'
            }
        },
        //colors: ['#FA8F4B', '#FEB27B', '#A1CEF8', '#0992C3', '#60D9E2', '#1CA487', '#80DCC8', '#EBAE04', '#FDE07C', '#E87A4E', '#FFBA9E'],
        colors: [PC_COLOR, MOBILE_COLOR],
        series: []
    },
    bar: {
        chart: {
            type: 'bar',
            style: {
                fontFamily: myOption.fontFamily
            }
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'category',
            title: {
                text: '키워드'
            }
        },
        yAxis: {
            min: 0,
            title: {
                text: '(%)',
                align: 'high'
            },
            labels: {
                overflow: 'justify'
            }
        },
        tooltip: {
            valueSuffix: '%'
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return Highcharts.numberFormat(this.y) + '%';
                    }
                }
            }
        },
        credits: {
            enabled: false
        },
        colors: ['#B1B4C0', '#A1CEF8', '#0992C3', '#60D9E2', '#1CA487', '#80DCC8', '#EBAE04', '#FDE07C', '#E87A4E', '#FFBA9E'],
        series: []
    },
    line: {
        chart: {
            type: 'line',
            style: {
                fontFamily: myOption.fontFamily
            }
        },
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            dateTimeLabelFormats: {
                day: '%Y.%m.%d',
                week: '%Y.%m.%d',
                month: '%Y.%m',
                year: '%Y'
            },
            labels: {
                //rotation: -25
            }
        },
        yAxis: [{
            title: {
                text: ''
            },
            labels: {
                style: {
                    color: '#4BA2F2'
                }
            }
        }, {
            opposite: true,
            title: {
                text: ''
            },
            labels: {
                style: {
                    color: '#3BDBEA'
                }
            }
        }],
        tooltip: {
            headerFormat: '<b>{point.x:%Y-%m-%d}</b><br>',
            pointFormat: '<span style="color:{series.color};">{series.name}: {point.y:.0f}</span><br/>',
            shared: true,
            crosshairs: true
        },
        colors: ['#4BA2F2', '#3BDBEA'],
        series: []
    },
    pie: {
        chart: {
            type: 'pie',
            style: {
                fontFamily: myOption.fontFamily
            }
        },
        title: {
            text: ''
        },
        tooltip: {
            headerFormat: '{point.key}: ',
            pointFormat: ' <b>{point.percentage:.1f}% / {point.y}명</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return this.percentage > 5 ? Math.round(this.percentage) + '%' : null;
                    },
                    size: '60%',
                    color: '#ffffff',
                    distance: -25
                },
                showInLegend: true,
                innerSize: '40%'
            }
        },
        colors: ['#0185CF', '#ED5565', '#FFCE54', '#48CFAD', '#444A59'],
        series: []
    }
};

// 차트 helper 함수
function updateChart($element, picker, data) {
    var idx = 0;
    var start = picker.startDate;
    var end = picker.endDate;
    var pcCount = 0;
    var mCount = 0;

    $.each(data, function (key, val) {
        var item = {
            data: []
        };

        for (var day = moment(start); day <= end; day.add(1, 'day')) {
            item.data.push([day.valueOf(), val[day.format('YYYY-MM-DD')] || null]);

            if (idx === 0) {
                pcCount += val[day.format('YYYY-MM-DD')] || 0;
            } else if (idx === 1) {
                mCount += val[day.format('YYYY-MM-DD')] || 0;
            }
        }

        $element.highcharts().series[idx].update(item, false);
        idx++;
    });

    $element.highcharts().redraw();

    var $pieElement = $('#pc-mobile-pie');
    if ($pieElement.highcharts() !== undefined) {
        var pieItem = {
            data: [['PC', pcCount], ['Mobile(예측)', mCount]]
        };

        $pieElement.highcharts().series[0].update(pieItem, true);
        $pieElement.highcharts().redraw();
    }
}

// init bar chart
function initColumnChart(elementId, data, options) {
    var $element = $('#' + elementId);

    var series = [];
    $.each(data, function (name) {
        series.push({name: name, data: []});
    });

    var _options = $.extend({}, window.chartOptions.column, options, {
        series: series
    });

    $element.highcharts(_options);

    $element.prev('.loading-bar').hide();
    $element.next('.loading-bar').hide();
    setNoData($element);

    // 차트에 DateRangePicker 연결
    $('.input-group-daterange[data-for-chart=' + elementId + ']').each(function () {
        // 캠페인 최초 시작일에 따라 Date range 기간 설정
        // setDefaultDateRangePicker($(this).data('daterangepicker'), data);
        // Date range 이벤트 등록
        $(this).on('change.daterangepicker', function () {
            updateChart(
                $element,
                $(this).data('daterangepicker'),
                data
            );
        }).trigger('change.daterangepicker');
    });
}

// init pie chart
function initPieChart(elementId, data, options) {
    var $element = $('#' + elementId);

    // no-data 출력을 위해
    data.data = (function (items) {
        for (var val in items) {
            if (items[val] !== null) {
                return items;
            }
        }
        return {};
    })(data.data);

    var series = [{name: data.name, data: []}];
    $.each(data.data, function (name, val) {
        if (name == '남자' || name == '남성') {
            series[0].data.push({name: name, y: val, color: '#0185CF'});
        } else if (name == '여자' || name == '여성') {
            series[0].data.push({name: name, y: val, color: '#ED5565'});
        } else {
            series[0].data.push({name: name, y: val});
        }
    });

    var _options = $.extend({}, window.chartOptions.pie, options, {
        series: series
    });

    // set blog color
    if (data.key == 'blog') {
        _options.colors = ['#48CFAD', '#FFCE54', '#ED5565', '#0185CF', '#444A59'];
    }

    $element.highcharts(_options);
    $element.prev('.loading-bar').hide();
    $element.next('.loading-bar').hide();
    setNoData($element);
}

// init line chart
function initLineChart(elementId, data) {
    var $element = $('#' + elementId);

    var series = [];
    $.each(data, function (name) {
        series.push({name: name, yAxis: series.length, data: []});
    });

    var options = $.extend({}, window.chartOptions.line, {
        series: series
    });

    $element.highcharts(options);

    $element.prev('.loading-bar').hide();
    $element.next('.loading-bar').hide();
    setNoData($element);

    // 차트에 DateRangePicker 연결
    $('.input-group-daterange[data-for-chart=' + elementId + ']').each(function () {
        // 캠페인 최초 시작일에 따라 Date range 기간 설정
        //setDefaultDateRangePicker($(this).data('daterangepicker'), data);

        // Date range 이벤트 등록
        $(this).on('change.daterangepicker', function () {
            updateChart(
                $element,
                $(this).data('daterangepicker'),
                data
            );
        }).trigger('change.daterangepicker');
    });
}

// daterangepicker helper 함수
function setDefaultDateRangePicker(picker, data) {
    var min, diff;
    $.each(data, function () {
        $.each(this, function (key) {
            if (min == undefined || min > key) {
                min = key;
            }
        });
    });

    diff = moment().diff(moment(min), 'days');

    if (diff > 90) {
        picker.setStartDate(moment().subtract(1, 'year').add(1, 'days'));
    } else {
        picker.setStartDate(moment().subtract(3, 'month').add(1, 'days'));
    }
    picker.setEndDate(moment());

    picker.callback(
        picker.startDate,
        picker.endDate,
        'Custom Range'
    );
}

// init bar chart
function initBarChart(elementId, data, options) {
    var $element = $('#' + elementId);

    var series = [];
    $.each(data, function (name, val) {
        series.push({name: name, data: val});
    });

    var _options = $.extend({}, window.chartOptions.bar, options, {
        series: series
    });

    $element.highcharts(_options);

    $element.prev('.loading-bar').hide();
    $element.next('.loading-bar').hide();
    setNoData($element);
}

// noData set
function setNoData($element) {
    var img = document.createElementNS('http://www.w3.org/2000/svg', 'image');
    img.setAttributeNS(null, 'height', '16');
    img.setAttributeNS(null, 'width', '16');
    img.setAttributeNS(null, 'x', '-18');
    img.setAttributeNS(null, 'y', '3');
    img.setAttributeNS('http://www.w3.org/1999/xlink', 'href', '/assets/images/campaigns/ic_none.svg');
    img.setAttributeNS(null, 'visibility', 'visible');

    $element.find('.highcharts-no-data').find('text').css('fill', '#999');
    $element.find('.highcharts-no-data').prepend(img);
}
